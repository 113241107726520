.App {
  background-color: #F7FEFF;
}

.logo {
  width: 15%;
  height: 15%;
  margin-left: 100px;
}

.label-size {
  font-size: 0.85rem;
}

.card-form {
  margin-top: 50px;
  margin-left: 100px;
  margin-bottom: 30px;
  width: 50%;

}

.card-details {
  width: 50%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.PhoneInputInput {
  flex: 1;
  min-width: 0;
  height: 2.7rem;
  font-size: 1rem;
  border-color: #dbdbdb;
  border-width: thin;
  border-style:solid;
  border-radius: 4px;
  color: #363636;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
}

.PhoneInputInput:hover {
  border-color: #b5b5b5;  
}

.PhoneInputInput:focus {
  border-color: #485fc7;
  box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25);
}

.btn-color {
  background-color: #36D1DC;
  border-color: #36D1DC;
}

.btn-color:hover {
  border-style: none;
}

.fp-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.9;
}

.indent-right {
  text-align-last: right;
}

.fp-container .fp-loader {
  top: 40%;
  bottom: 50%;
  left: 45%;
  right: 65%;
  z-index: 1000;
  position: absolute;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

form {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
